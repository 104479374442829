<template>
  <b-modal
    id="export-baseline-to-ibm-doors-next"
    title="Export Baseline to IBM DOORs Next"
    size="lg"
    ok-title="Export baseline to IBM DOORs"
    cancel-title="Close"
    :ok-disabled="!selectedProject || !storeURIs || exportLoading || step === 4"
    :ok-variant="step === 4 ? 'outline-secondary' : 'success'"
    :cancel-variant="step === 4 ? 'success' : 'outline-secondary'"
    no-close-on-backdrop
    no-close-on-esc
    @show="onShow"
    @hide="onHide"
    @ok.prevent="exportBaseline"
  >
    <div class="mb-2">
      <div v-if="step === 1" id="EDM_Step1" class="d-flex flex-column align-items-center">
        <feather-icon icon="AlertTriangleIcon" size="40" class="mt-2 text-warning" />
        <h3 class="mt-50 text-warning">
          Notice
        </h3>
        <h5 class="mt-50 mb-3 text-warning">
          Ensure the target DOORs Project is configured correctly
        </h5>
        <b-button
          variant="outline-warning"
          @click="acknowledgeWarningMsg"
        >
          Acknowledge
        </b-button>
      </div>

      <div v-if="step === 2" id="EDM_Step2">
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="IBM DOORs Instance"
                label-for="ddlDoorsInstance"
                label-cols-md="3"
              >
                <v-select
                  v-model="selectedInstance"
                  :options="instances"
                  :clearable="false"
                  @input="fetchProjects"
                />
              </b-form-group>
            </b-col>

            <!-- IBM DOORs Project -->
            <b-col cols="12">
              <b-form-group
                label="Project"
                label-for="ddlDoorsProject"
                label-cols-md="3"
              >
                <v-select
                  v-model="selectedProject"
                  label="title"
                  :options="projects"
                  :loading="loadingProjects"
                  :disabled="loadingProjects || !selectedInstance"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>

            <!-- Store uploaded DOORs URIs in Neo4J -->
            <b-col cols="12">
              <b-form-group
                label="Store DOORs URIs"
                label-for="storeURIs"
                label-cols-md="3"
              >
                <v-select
                  v-model="storeURIs"
                  label="label"
                  :options="storeUriOptions"
                  :clearable="false"
                  class="mb-1"
                />
                <p v-if="storeURIs !== null && storeURIs.value === true" class="font-small-3">
                  The URIs for each artifact created in DOORs will be stored in Kompozition.
                  These are used primarily to determine trace links when uploading future baselines.
                </p>
                <p v-else-if="storeURIs !== null && storeURIs.value === false" class="font-small-3">
                  Don't store the URIs for each artifact in Kompozition.
                  <br>
                  This is useful if you are experimenting with this feature or pushing unfinished data.
                </p>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>

        <b-row v-if="selectedProject" class="mt-2">
          <b-col cols="12" class="d-inline-flex justify-content-between align-items-center">
            <div>
              <b-card-title>Export to:
                <span class="text-primary">{{ selectedProject.title }}</span>
              </b-card-title>
              <b-card-sub-title>{{ selectedProject.description }}</b-card-sub-title>
            </div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              target="_blank"
              :disabled="!selectedProject"
              :href="selectedProject ? selectedProject.uri : ''"
            >
              Show in IBM DOORs
            </b-button>
          </b-col>
        </b-row>
      </div>

      <div v-if="step === 3" id="EDM_Step3" class="d-flex flex-column align-items-center">
        <b-spinner class="mt-3" />
        <h4 class="mt-1 mb-2 animate-pulse">
          Exporting baseline to
          <span class="text-primary">{{ selectedProject.title }}</span>
        </h4>
        <b-progress
          v-if="exportProgress !== null"
          striped
          animated
          :max="100"
          height="1.75rem"
          variant="info"
          class="mt-2 mb-2 w-100 progress-bar-info"
        >
          <b-progress-bar :value="exportProgress.progress.percentage">
            <span>Progress: <strong>{{ exportProgress.progress.processed }} / {{ exportProgress.progress.total }}</strong></span>
          </b-progress-bar>
        </b-progress>
        <h6 v-if="exportProgress !== null">
          Status: <strong>{{ exportProgress.status }}</strong>
        </h6>
        <h6 v-if="exportProgress !== null">
          Processing DOORs artifacts... <strong>{{ exportProgress.progress.processed }} / {{ exportProgress.progress.total }}</strong>
        </h6>

        <pre v-if="exportProgress !== null">{{ exportProgress.processing }}</pre>
      </div>

      <div v-if="step === 4" id="EDM_Step4">
        <div v-if="exportRequest.progress.percentage === 100">
          <div v-if="exportRequest.errors.length === 0" class="d-flex flex-column align-items-center">
            <feather-icon icon="CheckCircleIcon" size="40" class="my-2 text-success" />
            <h4 class="text-success">
              Success
            </h4>
            <h5 class="text-success">
              Exported {{ exportRequest.progress.total }} Requirements to IBM DOORs
            </h5>
            <h6 class="mt-1 mb-0 font-weight-bolder">
              <a :href="exportRequest.moduleUri" target="_blank">Open module in IBM DOORs</a>
            </h6>
          </div>

          <div v-if="exportRequest.errors.length > 0" class="d-flex flex-column align-items-center">
            <feather-icon icon="AlertTriangleIcon" size="40" class="my-2 text-warning" />
            <h4 class="text-warning">
              Completed with warnings
            </h4>
            <h5>
              Exported {{ exportRequest.progress.total }} Requirements to IBM DOORs.
            </h5>
            <h5 class="text-warning">
              However some failed to be converted to rich text.
            </h5>

            <pre class="mt-1">
Errors:
{{ exportRequest.errors }}
</pre>
            <h6 class="mt-1 font-weight-bolder">
              <a :href="exportRequest.moduleUri" target="_blank">Open module in IBM DOORs</a>
            </h6>
          </div>
        </div>

        <div v-else class="d-flex flex-column align-items-center">
          <feather-icon icon="XCircleIcon" size="40" class="my-2 text-danger" />
          <h4 class="text-danger">
            Failed: Error {{ exportRequest.status }} ({{ exportRequest.statusText }})
          </h4>
          <h5 class="text-danger">
            An unexpected error occurred when exporting the baseline to IBM DOORs
          </h5>
          <h6 class="text-danger">
            Please check for any new modules in the IBM DOORs Project and delete the unprocessed module in DOORs
          </h6>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import coreService from '@/libs/api-services/core-service'
import doorsIntegrationService from '@/libs/api-services/doors-service'
import { BProgress } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  name: 'ExportBaselineToDOORsModal',
  directives: {
    Ripple,
  },
  components: {
    BProgress,
    vSelect,
  },
  props: {
    baselineTarget: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const step = ref(1)

    const storeURIs = ref(null)
    const storeUriOptions = [
      { label: 'Yes, save the created DOORs URIs in Kompozition', value: true },
      { label: 'No, don\'t save the created DOORs URIs in Kompozition', value: false },
    ]

    const onShow = () => {
      storeURIs.value = null
      step.value = 1
      instances.value = []
      selectedInstance.value = null
      projects.value = []
      selectedProject.value = null
      trackerId.value = null
      exportProgress.value = null
      exportLoading.value = false
      fetchDoorsInstances()
    }

    const onHide = () => {
      trackerId.value = null
    }

    const acknowledgeWarningMsg = () => { step.value = 2 }

    const instances = ref([])
    const selectedInstance = ref(null)
    const fetchDoorsInstances = () => {
      instances.value = []
      coreService
        .get('/v1/legacy/doors/instances')
        .then(({ data }) => { instances.value = data })
        .catch(e => console.error(e))
    }

    const projects = ref([])
    const selectedProject = ref(null)
    const loadingProjects = ref(false)
    const fetchProjects = () => {
      clearProjectsList()
      loadingProjects.value = true
      const headers = { 'Doors-Base-URL': selectedInstance.value.url }
      coreService
        .get('/v1/legacy/doors/projects', { headers })
        .then(({ data }) => { projects.value = data })
        .catch(e => console.error(e))
        .finally(() => { loadingProjects.value = false })
    }
    const clearProjectsList = () => {
      projects.value = []
      selectedProject.value = null
    }

    const exportRequest = ref(null)
    const exportLoading = ref(false)
    const exportBaseline = () => {
      exportLoading.value = true
      step.value = 3

      const headers = { 'Doors-Base-URL': selectedInstance.value.url }
      const payload = {
        baseline: props.baselineTarget,
        storeURIs: storeURIs.value.value,
        instance: selectedInstance.value.label,
      }
      coreService.post(`/v1/legacy/doors/project/${selectedProject.value.id}/export`, payload, { headers })
        .then(response => {
          trackerId.value = response.data.trackerUUID
          trackProgress()
        })
        .catch(e => { console.error(e) })
    }

    const trackerId = ref(null)
    const exportProgress = ref(null)
    let retryCount = 0
    const trackProgress = () => {
      setTimeout(async () => {
        if (trackerId.value !== null) {
          doorsIntegrationService
            .get(`/api/v1/tracker/${trackerId.value}/progress`)
            // eslint-disable-next-line consistent-return
            .then(response => {
              exportProgress.value = response.data
              if (exportProgress.value.progress.percentage !== 100) {
                return trackProgress()
              }

              doorsIntegrationService
                .get(`/api/v1/tracker/${trackerId.value}`)
                .then(response => {
                  exportRequest.value = response.data
                  exportLoading.value = false
                  step.value = 4
                  return exportRequest.value
                })
            })
            .catch(e => {
              console.error(e)
              exportRequest.value = {
                status: 'error',
                message: e.response?.data ? e.response.data : e,
              }
              if (retryCount < 25) {
                retryCount++
                return trackProgress()
              }
              return null
            })
        }
      }, 1500)
    }

    return {
      onShow,
      onHide,
      step,
      storeUriOptions,
      storeURIs,
      acknowledgeWarningMsg,

      instances,
      selectedInstance,

      projects,
      selectedProject,
      loadingProjects,
      fetchProjects,

      exportRequest,
      exportLoading,
      exportBaseline,

      trackProgress,
      exportProgress,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
